// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-accounts-index-js": () => import("./../../../src/pages/admin/accounts/index.js" /* webpackChunkName: "component---src-pages-admin-accounts-index-js" */),
  "component---src-pages-admin-ci-index-js": () => import("./../../../src/pages/admin/ci/index.js" /* webpackChunkName: "component---src-pages-admin-ci-index-js" */),
  "component---src-pages-admin-data-transfer-management-index-js": () => import("./../../../src/pages/admin/data-transfer-management/index.js" /* webpackChunkName: "component---src-pages-admin-data-transfer-management-index-js" */),
  "component---src-pages-admin-middle-form-index-js": () => import("./../../../src/pages/admin/middleForm/index.js" /* webpackChunkName: "component---src-pages-admin-middle-form-index-js" */),
  "component---src-pages-admin-queues-index-js": () => import("./../../../src/pages/admin/queues/index.js" /* webpackChunkName: "component---src-pages-admin-queues-index-js" */),
  "component---src-pages-admin-yu-zhi-modal-index-js": () => import("./../../../src/pages/admin/yu-zhi-modal/index.js" /* webpackChunkName: "component---src-pages-admin-yu-zhi-modal-index-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-ci-ci-js": () => import("./../../../src/pages/ci/ci.js" /* webpackChunkName: "component---src-pages-ci-ci-js" */),
  "component---src-pages-ci-dashboard-js": () => import("./../../../src/pages/ci/dashboard.js" /* webpackChunkName: "component---src-pages-ci-dashboard-js" */),
  "component---src-pages-ci-gravatar-js": () => import("./../../../src/pages/ci/gravatar.js" /* webpackChunkName: "component---src-pages-ci-gravatar-js" */),
  "component---src-pages-ci-js": () => import("./../../../src/pages/ci.js" /* webpackChunkName: "component---src-pages-ci-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dmca-counternotice-create-js": () => import("./../../../src/pages/dmca/counternotice/create.js" /* webpackChunkName: "component---src-pages-dmca-counternotice-create-js" */),
  "component---src-pages-dmca-takedown-create-js": () => import("./../../../src/pages/dmca/takedown/create.js" /* webpackChunkName: "component---src-pages-dmca-takedown-create-js" */),
  "component---src-pages-dmca-takedown-government-create-js": () => import("./../../../src/pages/dmca/takedown/government/create.js" /* webpackChunkName: "component---src-pages-dmca-takedown-government-create-js" */),
  "component---src-pages-faq-detail-js": () => import("./../../../src/pages/faq/detail.js" /* webpackChunkName: "component---src-pages-faq-detail-js" */),
  "component---src-pages-faq-faq-js": () => import("./../../../src/pages/faq/faq.js" /* webpackChunkName: "component---src-pages-faq-faq-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot_password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inner-support-js": () => import("./../../../src/pages/inner-support.js" /* webpackChunkName: "component---src-pages-inner-support-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-loading-test-js": () => import("./../../../src/pages/loading-test.js" /* webpackChunkName: "component---src-pages-loading-test-js" */),
  "component---src-pages-metrics-artifactory-js": () => import("./../../../src/pages/metrics/artifactory.js" /* webpackChunkName: "component---src-pages-metrics-artifactory-js" */),
  "component---src-pages-metrics-email-js": () => import("./../../../src/pages/metrics/email.js" /* webpackChunkName: "component---src-pages-metrics-email-js" */),
  "component---src-pages-metrics-gitlab-js": () => import("./../../../src/pages/metrics/gitlab.js" /* webpackChunkName: "component---src-pages-metrics-gitlab-js" */),
  "component---src-pages-metrics-js": () => import("./../../../src/pages/metrics.js" /* webpackChunkName: "component---src-pages-metrics-js" */),
  "component---src-pages-metrics-parent-organization-detail-js": () => import("./../../../src/pages/metrics/parent-organization-detail.js" /* webpackChunkName: "component---src-pages-metrics-parent-organization-detail-js" */),
  "component---src-pages-metrics-project-detail-js": () => import("./../../../src/pages/metrics/project-detail.js" /* webpackChunkName: "component---src-pages-metrics-project-detail-js" */),
  "component---src-pages-metrics-servicedesk-js": () => import("./../../../src/pages/metrics/servicedesk.js" /* webpackChunkName: "component---src-pages-metrics-servicedesk-js" */),
  "component---src-pages-metrics-statistics-js": () => import("./../../../src/pages/metrics/statistics.js" /* webpackChunkName: "component---src-pages-metrics-statistics-js" */),
  "component---src-pages-metrics-system-js": () => import("./../../../src/pages/metrics/system.js" /* webpackChunkName: "component---src-pages-metrics-system-js" */),
  "component---src-pages-metrics-users-detail-js": () => import("./../../../src/pages/metrics/users-detail.js" /* webpackChunkName: "component---src-pages-metrics-users-detail-js" */),
  "component---src-pages-organization-[organizationname]-project-[projectslug]-metrics-js": () => import("./../../../src/pages/organization/[organizationname]/project/[projectslug]/metrics.js" /* webpackChunkName: "component---src-pages-organization-[organizationname]-project-[projectslug]-metrics-js" */),
  "component---src-pages-projects-detail-project-js": () => import("./../../../src/pages/projects/detail_project.js" /* webpackChunkName: "component---src-pages-projects-detail-project-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-projects-js": () => import("./../../../src/pages/projects/projects.js" /* webpackChunkName: "component---src-pages-projects-projects-js" */),
  "component---src-pages-reset-mfa-js": () => import("./../../../src/pages/reset_mfa.js" /* webpackChunkName: "component---src-pages-reset-mfa-js" */),
  "component---src-pages-secure-access-project-js": () => import("./../../../src/pages/secure/access-project.js" /* webpackChunkName: "component---src-pages-secure-access-project-js" */),
  "component---src-pages-secure-bulk-users-addusers-js": () => import("./../../../src/pages/secure/bulk-users-addusers.js" /* webpackChunkName: "component---src-pages-secure-bulk-users-addusers-js" */),
  "component---src-pages-secure-bulk-users-js": () => import("./../../../src/pages/secure/bulk-users.js" /* webpackChunkName: "component---src-pages-secure-bulk-users-js" */),
  "component---src-pages-secure-bulk-users-viewlogs-js": () => import("./../../../src/pages/secure/bulk-users-viewlogs.js" /* webpackChunkName: "component---src-pages-secure-bulk-users-viewlogs-js" */),
  "component---src-pages-secure-create-project-js": () => import("./../../../src/pages/secure/create-project.js" /* webpackChunkName: "component---src-pages-secure-create-project-js" */),
  "component---src-pages-secure-create-team-js": () => import("./../../../src/pages/secure/create-team.js" /* webpackChunkName: "component---src-pages-secure-create-team-js" */),
  "component---src-pages-secure-create-user-js": () => import("./../../../src/pages/secure/create-user.js" /* webpackChunkName: "component---src-pages-secure-create-user-js" */),
  "component---src-pages-secure-edit-project-js": () => import("./../../../src/pages/secure/edit-project.js" /* webpackChunkName: "component---src-pages-secure-edit-project-js" */),
  "component---src-pages-secure-js": () => import("./../../../src/pages/secure.js" /* webpackChunkName: "component---src-pages-secure-js" */),
  "component---src-pages-secure-manage-organization-js": () => import("./../../../src/pages/secure/manage-organization.js" /* webpackChunkName: "component---src-pages-secure-manage-organization-js" */),
  "component---src-pages-secure-parent-organization-settings-js": () => import("./../../../src/pages/secure/parent-organization-settings.js" /* webpackChunkName: "component---src-pages-secure-parent-organization-settings-js" */),
  "component---src-pages-secure-projectadmin-js": () => import("./../../../src/pages/secure/projectadmin.js" /* webpackChunkName: "component---src-pages-secure-projectadmin-js" */),
  "component---src-pages-secure-settings-js": () => import("./../../../src/pages/secure/settings.js" /* webpackChunkName: "component---src-pages-secure-settings-js" */),
  "component---src-pages-secure-system-settings-js": () => import("./../../../src/pages/secure/system-settings.js" /* webpackChunkName: "component---src-pages-secure-system-settings-js" */),
  "component---src-pages-secure-teamadmin-js": () => import("./../../../src/pages/secure/teamadmin.js" /* webpackChunkName: "component---src-pages-secure-teamadmin-js" */),
  "component---src-pages-secure-transfersummary-js": () => import("./../../../src/pages/secure/transfersummary.js" /* webpackChunkName: "component---src-pages-secure-transfersummary-js" */),
  "component---src-pages-secure-useradmin-js": () => import("./../../../src/pages/secure/useradmin.js" /* webpackChunkName: "component---src-pages-secure-useradmin-js" */),
  "component---src-pages-secure-view-project-js": () => import("./../../../src/pages/secure/view-project.js" /* webpackChunkName: "component---src-pages-secure-view-project-js" */),
  "component---src-pages-sso-js": () => import("./../../../src/pages/sso.js" /* webpackChunkName: "component---src-pages-sso-js" */),
  "component---src-pages-support-artifactory-create-artifactory-repository-js": () => import("./../../../src/pages/support/artifactory/create-artifactory-repository.js" /* webpackChunkName: "component---src-pages-support-artifactory-create-artifactory-repository-js" */),
  "component---src-pages-support-artifactory-delete-artifactory-repository-js": () => import("./../../../src/pages/support/artifactory/delete-artifactory-repository.js" /* webpackChunkName: "component---src-pages-support-artifactory-delete-artifactory-repository-js" */),
  "component---src-pages-support-artifactory-upload-an-artifact-js": () => import("./../../../src/pages/support/artifactory/upload-an-artifact.js" /* webpackChunkName: "component---src-pages-support-artifactory-upload-an-artifact-js" */),
  "component---src-pages-support-email-request-email-archive-js": () => import("./../../../src/pages/support/email/request_email_archive.js" /* webpackChunkName: "component---src-pages-support-email-request-email-archive-js" */),
  "component---src-pages-support-email-request-email-metrics-js": () => import("./../../../src/pages/support/email/request_email_metrics.js" /* webpackChunkName: "component---src-pages-support-email-request-email-metrics-js" */),
  "component---src-pages-support-github-add-a-user-to-repository-js": () => import("./../../../src/pages/support/github/add-a-user-to-repository.js" /* webpackChunkName: "component---src-pages-support-github-add-a-user-to-repository-js" */),
  "component---src-pages-support-github-create-a-fork-js": () => import("./../../../src/pages/support/github/create-a-fork.js" /* webpackChunkName: "component---src-pages-support-github-create-a-fork-js" */),
  "component---src-pages-support-github-create-a-repository-js": () => import("./../../../src/pages/support/github/create-a-repository.js" /* webpackChunkName: "component---src-pages-support-github-create-a-repository-js" */),
  "component---src-pages-support-github-remove-a-repository-js": () => import("./../../../src/pages/support/github/remove-a-repository.js" /* webpackChunkName: "component---src-pages-support-github-remove-a-repository-js" */),
  "component---src-pages-support-github-remove-a-user-from-repository-js": () => import("./../../../src/pages/support/github/remove-a-user-from-repository.js" /* webpackChunkName: "component---src-pages-support-github-remove-a-user-from-repository-js" */),
  "component---src-pages-support-gitlab-clean-a-repository-js": () => import("./../../../src/pages/support/gitlab/clean-a-repository.js" /* webpackChunkName: "component---src-pages-support-gitlab-clean-a-repository-js" */),
  "component---src-pages-support-gitlab-create-a-fork-js": () => import("./../../../src/pages/support/gitlab/create-a-fork.js" /* webpackChunkName: "component---src-pages-support-gitlab-create-a-fork-js" */),
  "component---src-pages-support-gitlab-create-a-mirror-js": () => import("./../../../src/pages/support/gitlab/create-a-mirror.js" /* webpackChunkName: "component---src-pages-support-gitlab-create-a-mirror-js" */),
  "component---src-pages-support-gitlab-create-a-project-group-js": () => import("./../../../src/pages/support/gitlab/create-a-project-group.js" /* webpackChunkName: "component---src-pages-support-gitlab-create-a-project-group-js" */),
  "component---src-pages-support-gitlab-create-a-project-subgroup-js": () => import("./../../../src/pages/support/gitlab/create-a-project-subgroup.js" /* webpackChunkName: "component---src-pages-support-gitlab-create-a-project-subgroup-js" */),
  "component---src-pages-support-gitlab-create-a-repository-js": () => import("./../../../src/pages/support/gitlab/create-a-repository.js" /* webpackChunkName: "component---src-pages-support-gitlab-create-a-repository-js" */),
  "component---src-pages-support-gitlab-delete-a-project-group-js": () => import("./../../../src/pages/support/gitlab/delete-a-project-group.js" /* webpackChunkName: "component---src-pages-support-gitlab-delete-a-project-group-js" */),
  "component---src-pages-support-gitlab-delete-a-project-subgroup-js": () => import("./../../../src/pages/support/gitlab/delete-a-project-subgroup.js" /* webpackChunkName: "component---src-pages-support-gitlab-delete-a-project-subgroup-js" */),
  "component---src-pages-support-gitlab-delete-a-repository-js": () => import("./../../../src/pages/support/gitlab/delete-a-repository.js" /* webpackChunkName: "component---src-pages-support-gitlab-delete-a-repository-js" */),
  "component---src-pages-support-gitlab-disable-dco-for-repository-js": () => import("./../../../src/pages/support/gitlab/disable-dco-for-repository.js" /* webpackChunkName: "component---src-pages-support-gitlab-disable-dco-for-repository-js" */),
  "component---src-pages-support-gitlab-enable-dco-for-repository-js": () => import("./../../../src/pages/support/gitlab/enable-dco-for-repository.js" /* webpackChunkName: "component---src-pages-support-gitlab-enable-dco-for-repository-js" */),
  "component---src-pages-support-irc-remove-user-access-for-irc-js": () => import("./../../../src/pages/support/irc/remove-user-access-for-irc.js" /* webpackChunkName: "component---src-pages-support-irc-remove-user-access-for-irc-js" */),
  "component---src-pages-support-irc-request-user-access-to-irc-js": () => import("./../../../src/pages/support/irc/request-user-access-to-irc.js" /* webpackChunkName: "component---src-pages-support-irc-request-user-access-to-irc-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-support-other-feature-request-js": () => import("./../../../src/pages/support/other/feature_request.js" /* webpackChunkName: "component---src-pages-support-other-feature-request-js" */),
  "component---src-pages-support-other-informational-js": () => import("./../../../src/pages/support/other/informational.js" /* webpackChunkName: "component---src-pages-support-other-informational-js" */),
  "component---src-pages-support-other-inquiry-js": () => import("./../../../src/pages/support/other/inquiry.js" /* webpackChunkName: "component---src-pages-support-other-inquiry-js" */),
  "component---src-pages-support-other-report-a-service-outage-or-disruption-js": () => import("./../../../src/pages/support/other/report_a_service_outage_or_disruption.js" /* webpackChunkName: "component---src-pages-support-other-report-a-service-outage-or-disruption-js" */),
  "component---src-pages-support-other-service-request-js": () => import("./../../../src/pages/support/other/service_request.js" /* webpackChunkName: "component---src-pages-support-other-service-request-js" */),
  "component---src-pages-support-projects-add-users-to-project-role-js": () => import("./../../../src/pages/support/projects/add-users-to-project-role.js" /* webpackChunkName: "component---src-pages-support-projects-add-users-to-project-role-js" */),
  "component---src-pages-support-projects-archive-public-project-js": () => import("./../../../src/pages/support/projects/archive-public-project.js" /* webpackChunkName: "component---src-pages-support-projects-archive-public-project-js" */),
  "component---src-pages-support-projects-change-project-status-js": () => import("./../../../src/pages/support/projects/change-project-status.js" /* webpackChunkName: "component---src-pages-support-projects-change-project-status-js" */),
  "component---src-pages-support-projects-create-project-js": () => import("./../../../src/pages/support/projects/create-project.js" /* webpackChunkName: "component---src-pages-support-projects-create-project-js" */),
  "component---src-pages-support-projects-delete-project-js": () => import("./../../../src/pages/support/projects/delete-project.js" /* webpackChunkName: "component---src-pages-support-projects-delete-project-js" */),
  "component---src-pages-support-projects-export-project-js": () => import("./../../../src/pages/support/projects/export-project.js" /* webpackChunkName: "component---src-pages-support-projects-export-project-js" */),
  "component---src-pages-support-projects-remove-users-to-project-role-js": () => import("./../../../src/pages/support/projects/remove-users-to-project-role.js" /* webpackChunkName: "component---src-pages-support-projects-remove-users-to-project-role-js" */),
  "component---src-pages-support-projects-request-project-deduplication-js": () => import("./../../../src/pages/support/projects/request-project-deduplication.js" /* webpackChunkName: "component---src-pages-support-projects-request-project-deduplication-js" */),
  "component---src-pages-support-summary-js": () => import("./../../../src/pages/support/summary.js" /* webpackChunkName: "component---src-pages-support-summary-js" */),
  "component---src-pages-support-users-create-user-bulk-js": () => import("./../../../src/pages/support/users/create-user-bulk.js" /* webpackChunkName: "component---src-pages-support-users-create-user-bulk-js" */),
  "component---src-pages-support-users-create-user-js": () => import("./../../../src/pages/support/users/create-user.js" /* webpackChunkName: "component---src-pages-support-users-create-user-js" */),
  "component---src-pages-support-users-disable-user-service-js": () => import("./../../../src/pages/support/users/disable-user-service.js" /* webpackChunkName: "component---src-pages-support-users-disable-user-service-js" */),
  "component---src-pages-support-users-enable-user-service-js": () => import("./../../../src/pages/support/users/enable-user-service.js" /* webpackChunkName: "component---src-pages-support-users-enable-user-service-js" */),
  "component---src-pages-support-users-modify-user-js": () => import("./../../../src/pages/support/users/modify-user.js" /* webpackChunkName: "component---src-pages-support-users-modify-user-js" */),
  "component---src-pages-support-users-remove-user-service-js": () => import("./../../../src/pages/support/users/remove-user-service.js" /* webpackChunkName: "component---src-pages-support-users-remove-user-service-js" */),
  "component---src-pages-support-users-reset-mfa-js": () => import("./../../../src/pages/support/users/reset-mfa.js" /* webpackChunkName: "component---src-pages-support-users-reset-mfa-js" */),
  "component---src-pages-support-users-reset-password-js": () => import("./../../../src/pages/support/users/reset-password.js" /* webpackChunkName: "component---src-pages-support-users-reset-password-js" */),
  "component---src-pages-support-users-update-user-status-js": () => import("./../../../src/pages/support/users/update-user-status.js" /* webpackChunkName: "component---src-pages-support-users-update-user-status-js" */),
  "component---src-pages-support-wiki-create-a-wiki-js": () => import("./../../../src/pages/support/wiki/create-a-wiki.js" /* webpackChunkName: "component---src-pages-support-wiki-create-a-wiki-js" */),
  "component---src-pages-support-wiki-delete-a-wiki-js": () => import("./../../../src/pages/support/wiki/delete-a-wiki.js" /* webpackChunkName: "component---src-pages-support-wiki-delete-a-wiki-js" */),
  "component---src-pages-trademark-policy-report-js": () => import("./../../../src/pages/trademark/policy/report.js" /* webpackChunkName: "component---src-pages-trademark-policy-report-js" */),
  "component---src-pages-user-registration-incomplete-index-js": () => import("./../../../src/pages/user/registration/incomplete/index.js" /* webpackChunkName: "component---src-pages-user-registration-incomplete-index-js" */),
  "component---src-templates-md-template-js": () => import("./../../../src/templates/mdTemplate.js" /* webpackChunkName: "component---src-templates-md-template-js" */)
}

