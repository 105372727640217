import React, { Suspense, lazy, useEffect } from "react";
import { navigate } from 'gatsby'

const Auth0Provider = lazy(() => import('@auth0/auth0-react').then(module => ({ default: module.Auth0Provider })))

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/')
};

export const wrapRootElement = ({ element }) => {
  return (
    <Suspense fallback={<div>Loading Authentication...</div>}>
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENTID}
        audience={process.env.AUTH0_AUDIENCE}
        redirectUri={process.env.AUTH0_CALLBACK}
        onRedirectCallback={onRedirectCallback}
      >
        {element}
      </Auth0Provider>
    </Suspense>
  );
};

